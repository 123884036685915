/* eslint-disable func-names */
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { User } from 'types/auth'

export const useIntercom = (user?: User) => {
  const router = useRouter()

  useEffect(() => {
    if (!user?.intercomHash) {
      return
    }
    ;(function () {
      // @ts-expect-error
      window.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        user_id: user.userName, // cognito username
        user_hash: user.intercomHash, // an Identity Verification user hash for your user
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const w: any = window
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ic('update', w.intercomSettings)
      } else {
        const d = document
        // eslint-disable-next-line func-names
        const i = function () {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments)
        }
        // @ts-expect-error
        i.q = []
        // @ts-expect-error
        i.c = function (args) {
          i.q.push(args)
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        w.Intercom = i
        const l = function () {
          const s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = `https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}`
          const x = d.getElementsByTagName('script')[0]
          x.parentNode?.insertBefore(s, x)
        }
        if (document.readyState === 'complete') {
          l()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        } else if (w.attachEvent) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          w.attachEvent('onload', l)
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          w.addEventListener('load', l, false)
        }
      }
    })()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    ;(window as any).Intercom('boot', { app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    ;(window as any).Intercom('update', {
      name: user.name,
      email: user.email,
      user_id: user.userName, // cognito username
      user_hash: user.intercomHash, // an Identity Verification user hash for your user
    })
  }, [user?.email, user?.intercomHash, user?.name, user?.userName])

  useEffect(() => {
    const handleRouteChange = () => {
      if (!user?.intercomHash) {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
      ;(window as any).Intercom('update', {
        name: user.name,
        email: user.email,
        user_id: user.userName, // cognito username
        user_hash: user.intercomHash, // an Identity Verification user hash for your user
      })
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, user?.email, user?.intercomHash, user?.name, user?.userName])
}
