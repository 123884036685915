import { useIntercom } from 'hooks/useIntercom'
import { useSession } from 'next-auth/react'

export const IntercomWrapper = () => {
  const user = useSession()
  useIntercom(user.data?.user)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
