import { toast, Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => (
  <Sonner
    className="toaster group"
    position="bottom-center"
    toastOptions={{
      unstyled: true,
      classNames: {
        toast:
          '[&>[data-icon]]:hidden [&>[data-content]>[data-title]]:font-normal w-full text-sm text-secondary-50 flex items-center px-4 py-3 rounded-lg md:max-w-[420px] shadow-lg',
        description: 'group-[.toast]:text-muted-foreground',
        actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
        cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        error: 'bg-danger',
        info: 'bg-secondary-900',
      },
    }}
    {...props}
  />
)

export { toast, Toaster }
