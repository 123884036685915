import React from 'react'
import { add, init, track } from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { useSession } from 'next-auth/react'
import { AmplitudeEventProperties } from 'types/common'

const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string

type AmplitudeProviderProps = {
  children: React.ReactNode
}

type ContextType = {
  trackAmplitudeEvent: (eventName: string, eventProperties: AmplitudeEventProperties) => void
}

export const AmplitudeContext = React.createContext({} as ContextType)

export const AmplitudeProvider = ({ children }: AmplitudeProviderProps) => {
  const session = useSession()

  React.useEffect(() => {
    init(AMPLITUDE_API_KEY, session.data?.user.email, {
      defaultTracking: {
        sessions: true,
      },
    })

    // https://www.docs.developers.amplitude.com/session-replay/sdks/plugin/#sampling-rate
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 0,
    })
    add(sessionReplayTracking)
  }, [session.data?.user.email])

  const momoizedValue = React.useMemo(() => {
    const trackAmplitudeEvent = (eventName: string, eventProperties: AmplitudeEventProperties) => {
      track(eventName, eventProperties)
    }

    return { trackAmplitudeEvent }
  }, [])

  return <AmplitudeContext.Provider value={momoizedValue}>{children}</AmplitudeContext.Provider>
}
